import { Component, Host, Prop, h } from '@stencil/core';

@Component({
    tag: 'oag-project',
    styleUrl: 'oag-project.scss',
    shadow: true,
})
export class OagProject {
    @Prop() public title: string = '';
    @Prop() public subtitle: string = '';
    @Prop() public href: string = '';
    @Prop() public hrefLabel: string = 'Projekt ansehen';
    @Prop() public backgroundImage: string = '';
    @Prop() public backgroundOverlay: string = '';
    @Prop() public backgroundPosition: string = 'center center';

    render() {
        return (
            <Host onClick={() => this.open()}>
                <div class="container" style={{ backgroundImage: 'url(' + this.backgroundImage + ')', backgroundPosition: this.backgroundPosition }}>
                    <div class="open">
                        <svg version="1.1" viewBox="0 0 64 64">
                            <g>
                                <g id="Icon-External-Link" transform="translate(382.000000, 380.000000)">
                                    <polyline class="st0" id="Fill-118" points="-352.3,-343.4 -354.6,-345.7 -328.8,-371.4 -326.6,-369.2 -352.3,-343.4    " />
                                    <polyline class="st0" id="Fill-119" points="-326,-354.9 -329.4,-354.9 -329.4,-368.6 -343.1,-368.6 -343.1,-372 -326,-372      -326,-354.9    " />
                                    <path
                                        class="st0"
                                        d="M-334.6-324h-34.3c-2.8,0-5.1-2.3-5.1-5.1v-34.3c0-2.8,2.3-5.1,5.1-5.1h18.9v3.4h-18.9     c-0.9,0-1.7,0.8-1.7,1.7v34.3c0,0.9,0.8,1.7,1.7,1.7h34.3c0.9,0,1.7-0.8,1.7-1.7V-348h3.4v18.9C-329.4-326.3-331.7-324-334.6-324     "
                                        id="Fill-120"
                                    />
                                </g>
                            </g>
                        </svg>
                        <h3>{this.hrefLabel}</h3>
                    </div>
                    <div class="overlay" style={{ background: this.backgroundOverlay }}>
                        <div class="icon">
                            <slot name="icon"></slot>
                        </div>
                        <div class="content">
                            <h3 class="title">{this.title}</h3>
                            <p class="subtitle">{this.subtitle}</p>
                            <div class="content">
                                <slot></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </Host>
        );
    }

    public open() {
        if (this.href) {
            window.location.href = this.href;
        }
    }
}
